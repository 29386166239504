.Response {
  margin: 30px;
}
.Response_content {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  margin: auto;
  text-align: center;
}
.Response_content img {
  width: 150px;
}
