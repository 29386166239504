.Checkout {
  margin: 30px 0;
}
.Checkout_bread-crumbs {
  margin-bottom: 20px;
}
.Checkout_bread-crumbs a {
  color: #000000;
}
.Checkout_bread-crumbs a:nth-child(1) {
  color: #e50019;
}
.Checkout_bread-crumbs a:hover {
  color: var(--red);
}
.Checkout_box-content {
  width: 100%;
  padding: 25px 25px;
  border-radius: 20px;
  background: #e50019;
  position: relative;
  color: var(--white);
}
.Checkout_event-title {
  text-align: center;
  margin-bottom: 20px;
}
.Checkout_event-cover {
  width: 100%;
}
.Checkout_content {
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  border-radius: 15px;
  color: var(--gray);
}
.Checkout_content h5 {
  color: #e50019;
}
.Checkout_content h5 .subtitle {
  color: var(--black);
}
.Checkout_box-selected {
  padding: 15px;
  background-color: var(--ligthGray);
  border-radius: 10px;
  margin-bottom: 20px;
  color: #000000;
}
.Checkout_row-selected {
  display: flex;
  flex-wrap: wrap;
}
.Checkout_select {
  width: 80px;
  height: 100px;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: var(--alphaWhite);
  text-transform: uppercase;
  border: 0;
  border: 2px solid var(--alphaWhite);
  transition: 0.2s all;
}
.Checkout_location {
  width: 130px;
  height: 100px;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: var(--alphaWhite);
  border: 0;
  border: 2px solid var(--alphaWhite);
  transition: 0.2s all;
  font-weight: 900;
  font-size: 18px;
}
.Checkout_location .color-red {
  font-size: 18px;
  font-weight: 900;
}
.Checkout_location.selected .color-red {
  color: var(--black);
}
.Checkout_location:hover,
.Checkout_select:hover {
  border: 2px solid var(--red);
}
.Checkout_location:focus,
.Checkout_select:focus {
  outline: none;
}
.Checkout_select-day {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  padding: 0 10px;
}
.Checkout_location.selected,
.Checkout_select.selected {
  background-color: var(--red);
  color: var(--white);
  border: 2px solid var(--red);
}
.Checkout_location .small {
  font-size: 13px;
  line-height: 16px;
}
.Checkout_price {
  color: var(--red);
  font-size: 21px;
  line-height: 21px;
  margin: 5px 0;
}
.Checkout_box-other-price {
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  padding: 3px 0;
}
.Checkout_other-price {
  color: var(--red);
  font-weight: bold;
  font-size: 15px;
}
.Checkout_location.selected .Checkout_price {
  color: var(--black);
}
.Checkout_location.selected .Checkout_price {
  color: var(--black);
}
.Checkout_resume {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  color: var(--gray);
}
.Checkout_location span {
  font-size: 12px;
  font-weight: initial;
}
.Checkout_menu-01,
.Checkout_menu-02 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Checkout_button {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  font-weight: 900;
  transition: 0.2s all;
}
.Checkout_button:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}
.Checkout_next {
  background-color: var(--red);
  color: var(--white);
}
.Checkout_menu-02 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--ligthGray);
}
.Checkout_date {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  flex-wrap: wrap;
  font-size: 18px;
}
.Checkout_date div {
  margin: 0 10px;
}
.Checkout_date div span {
  color: var(--red);
}
.Checkout_cinema {
  text-align: center;
  color: var(--red);
}
.Checkout_3d-message {
  width: 100%;
  max-width: 600px;
  text-align: center;
  font-size: 20px;
  margin: 0 auto 20px;
  color: var(--mediumGray);
}
.Checkout_3d-message-footer {
  width: 100%;
  max-width: 300px;
  padding: 0 20px;
}
.Checkout_image img {
  width: 100%;
}
.Checkout_face-mask {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: var(--red);
  color: var(--white);
  text-align: center;
  border-radius: 20px;
  margin-bottom: 20px;
}
.Checkout_banner {
  margin-bottom: 15px;
  border: 0;
}
.Checkout_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Checkout_modal-content {
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  text-align: center;
}
.Checkout_modal-content h3 {
  font-size: 24px;
}
.Checkout_modal-content h2 {
  color: #e50019;
}
.Checkout_modal-close {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e50019;
  font-size: 30px;
  color: white;
  padding: 0;
  border: 0;
  position: absolute;
  top: -10px;
  right: -10px;
}
.Checkout_modal-close:focus {
  outline: none;
}
.Checkout_modal-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.Checkout_modal-menu a {
  color: #000000;
}
.Checkout_modal-menu a:hover {
  color: black;
}
.Checkout_option {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.Checkout_option.show {
  visibility: visible;
  opacity: 1;
  display: block;
}
.Checkout_menu-reservation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.Checkout_menu-reservation button {
  width: 100%;
  padding: 7px 10px;
  border: 0;
  transition: 0.2s all;
  color: var(--white);
  text-align: center;
  border-radius: 10px;
  background-color: var(--black);
}
.Checkout_menu-reservation button:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 1200px) {
  .Checkout_3d-message-footer {
    width: 100%;
  }
}
.Checkout_confectionery-button {
  border: 0;
  margin-bottom: 10px;
  background-color: var(--black);
  color: var(--white);
  padding: 8px 10px;
  border-radius: 10px;
}
.Checkout_confectionery-button i.fa-arrow-left {
  margin-right: 5px;
}
.Checkout_confectionery-button i.fa-arrow-right {
  margin-left: 5px;
}
.Checkout_confectionery-menu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Checkout_confectionery-menu button {
  margin-bottom: 10px;
}
.Checkout_form label {
  margin-bottom: 0px;
  font-size: 14px;
}
