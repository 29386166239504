.HeaderCity-content {
  position: relative;
  margin-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 25px;
}
.HeaderCity-select {
  width: 90%;
  max-width: 600px;
  height: 50px;
  border-radius: 10px 10px 20px 20px;
  background-color: var(--red);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -30px;
  color: var(--white);
}
.HeaderCity-select select {
  border: 0;
  background-color: var(--white);
  padding: 3px 10px;
  margin-left: 10px;
  border-radius: 10px;
}
.HeaderCity-sliders {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 1100px) {
  .HeaderCity-content {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 330px) {
  .HeaderCity-select {
    font-size: 14px;
  }
}
