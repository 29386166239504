.Tickets_item {
  margin-bottom: 20px;
}
.Tickets_item img {
  width: 100%;
}
.Tickets_item-content {
  width: 100%;
  padding: 10px 15px 15px;
  border: 1px solid var(--ligthGray);
}
.Tickets_item-content h3 {
  font-size: 18px;
}
.Tickets_btn {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  transition: 0.2s all;
}
.Tickets_btn:hover {
  text-decoration: none;
}
.Tickets_btn.code {
  background-color: var(--red);
  color: var(--white);
}
.Tickets_btn.code:hover {
  background-color: var(--gray);
  color: var(--white);
}
.Tickets_btn.status {
  background-color: var(--gray);
  color: var(--white);
}
.Tickets_btn.status:hover {
  background-color: var(--ligthGray);
  color: #000000;
}
