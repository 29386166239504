.PoliciesPage {
  margin: 30px auto;
}
.PoliciesPage h1,
.PoliciesPage h2,
.PoliciesPage h3,
.PoliciesPage h4,
.PoliciesPage h5,
.PoliciesPage h6 {
  color: var(--red);
}
.PoliciesPage h1 {
  text-align: center;
  font-size: 26px;
  margin-bottom: 20px;
}
.PoliciesPage h2 {
  font-size: 20px;
}
