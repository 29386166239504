.SectionTitle-content {
  padding-top: 20px;
}
.SectionTitle-content .col-12 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.SectionTitle-content .SectionTitle-title {
  padding-right: 10px;
  border-bottom: 3px solid var(--red);
  font-weight: bold;
  font-size: 18px;
}
.SectionTitle-content.big .SectionTitle-title {
  font-size: 24px;
}
.SectionTitle-content.white {
  margin-bottom: 10px;
}
.SectionTitle-content.black {
  background-color: var(--darkGray);
}
.SectionTitle-content.black .SectionTitle-title,
.SectionTitle-content.black .SectionTitle-subtitle {
  color: var(--white);
}
