.CorporativeForm-container {
  padding: 20px 0 25px;
  position: relative;
}
.CorporativeForm-container h3 {
  text-align: center;
  color: var(--white);
  text-decoration: underline;
  margin-bottom: 30px;
}
.CorporativeForm-container .form-group {
  text-align: center;
  color: var(--white);
}
.CorporativeForm-container input,
.CorporativeForm-container select,
.CorporativeForm-container textarea {
  width: 100%;
  padding: 3px 10px;
  border-radius: 5px 5px 10px 10px;
  border: 0;
  background-color: var(--white);
  color: var(--black);
}
.CorporativeForm-container input,
.CorporativeForm-container select {
  height: 35px;
}
.CorporativeForm-container input:focus,
.CorporativeForm-container select:focus,
.CorporativeForm-container textarea:focus,
.CorporativeForm-container button:focus {
  outline: none;
}
.CorporativeForm-container button {
  width: 100px;
  height: 50px;
  background-color: var(--red);
  color: var(--white);
  border: 0;
  border-radius: 5px 5px 10px 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  display: block;
  margin: 0 auto -40px;
  transition: 0.2s all;
}
.CorporativeForm-container button:hover {
  background-color: var(--black);
}
.CorporativeForm-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.75);
}
.CorporativeForm-loading img {
  width: 50px;
}
.CorporativeForm-terms {
  color: var(--white);
}
.CorporativeForm-terms a {
  color: var(--red);
  text-decoration: underline;
}
.CorporativeForm-terms a:hover {
  color: var(--white);
}
.CorporativeForm-container ul {
  list-style: none;
  margin-top: 5px;
}
.CorporativeForm-iframe {
  width: 100%;
  min-height: 645px;
}
.CorporativeForm-container.white {
  background-color: var(--white);
  padding: 10px 0 0;
}
body.ff_landing_page_body {
  background-color: transparent !important;
}
