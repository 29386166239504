.MenuProfile {
  width: 100%;
  height: 40px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.MenuProfile a,
.MenuProfile button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  background-color: var(--red);
  border: 0;
  color: var(--white);
  transition: 0.2s all;
  text-align: center;
}
.MenuProfile a:hover,
.MenuProfile button:hover,
.MenuProfile a.active {
  background-color: var(--gray);
  text-decoration: none;
  color: var(--white);
}
.MenuProfile a {
  border-left: 1px solid #ffffff;
}
.MenuProfile a:first-child {
  border-radius: 20px 0 0 0;
  border-left: 0;
}
.MenuProfile button {
  border-radius: 0 20px 0 0;
  border-left: 1px solid #ffffff;
}
@media screen and (max-width: 370px) {
  .MenuProfile a,
  .MenuProfile button {
    font-size: 14px;
    padding: 0 10px;
  }
}
