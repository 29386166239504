.Erro404Page_container {
  width: 300px;
  height: 300px;
  background: url('../../assets/images/error.png') center no-repeat;
  background-size: 100% 100%;
  display: block;
  margin: 40px auto;
  text-align: center;
  padding-top: 150px;
  color: #ffffff;
}
.Erro404Page_container h1 {
  font-size: 80px;
  margin: 0;
}
