.ModalLoading {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all;
  z-index: 50;
  flex-direction: column;
}
.ModalLoading.visible {
  visibility: visible;
  opacity: 1;
}
.ModalLoading img {
  width: 150px;
}
.ModalLoading div {
  color: var(--white);
  text-align: center;
  font-size: 18px;
}
