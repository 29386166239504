.Imax-container {
  padding: 20px 0 40px;
  text-align: center;
}
.Imax-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Imax-row h3 {
  color: var(--red);
}
.Imax-title {
  background-color: var(--red);
  color: var(--white);
  font-weight: bold;
  font-size: 20px;
  padding: 3px 15px;
  border-radius: 5px 5px 8px 8px;
  margin: 40px 0 70px;
}
.Imax-p-1 {
  width: 100%;
  max-width: 400px;
}
.Imax-image {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}
.Imax-image img {
  width: 100%;
}
.Item-subtitle {
  color: var(--red);
}
.Imax-p-2 {
  width: 100%;
  max-width: 700px;
  margin: 20px auto 0;
}
