.Dates {
  display: flex;
  justify-content: center;
  flex-direction: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.Dates_button {
  width: 110px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid var(--gray);
  background-color: var(--gray);
  color: var(--white);
  margin: 0 5px 10px;
  transition: 0.2s all;
}
.Dates_button:focus {
  outline: none;
}
.Dates_button:hover {
  background-color: var(--ligthGray);
  color: var(--gray);
}
.Dates_button.selected {
  background-color: var(--red) !important;
  border: 2px solid var(--red) !important;
  color: #ffffff !important;
}
