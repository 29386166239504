.modalProduct {
  background-color: var(--modal);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all;
}
.modalProduct.show {
  visibility: visible;
  opacity: 1;
}
.modalProduct_content {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  max-width: 400px;
  background-color: var(--white);
  margin: 20px auto;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.modalProduct_container {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.modalProduct_header {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.modalProduct_header button {
  width: 40px;
  height: 40px;
  background-color: var(--red);
  color: var(--white);
  font-weight: bold;
  transition: 0.2s all;
  border: 0;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.modalProduct_header button:hover {
  background-color: var(--white);
  color: var(--red);
}
.modalProduct_box-name {
  width: 100%;
  position: relative;
}
.modalProduct_name {
  width: 100%;
  text-align: center;
  background-color: var(--black);
  padding: 5px 10px;
  color: var(--white);
}
.modalProduct_name h2 {
  color: var(--red);
  font-size: 20px;
  margin-bottom: 0;
}
.modalProduct_name h4 {
  font-size: 18px;
  margin-bottom: 0;
}
.modalProduct_box-name img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: bottom;
  border-radius: 10px 10px 0 0;
}
.modalProduct_footer {
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  border-radius: 15px 15px 0 0;
  padding-top: 10px;
}
.modalProduct_body {
  width: 100%;
  margin-bottom: 10px;
}
.modalProduct_body h4 {
  font-size: 18px;
  text-align: center;
  padding: 0 10px;
}
.modalProduct_recipe {
  padding: 0 10px;
  margin-bottom: 15px;
}
.modalProduct_recipe-header {
  font-weight: 700;
  padding: 5px 10px;
  font-size: 16px;
  border-bottom: 2px solid var(--handGray);
  color: var(--red);
}
.modalProduct_recipe-header.active {
  border-bottom: 1px solid var(--handGray);
  margin-bottom: 20px;
  color: var(--red);
}
.modalProduct_recipe-header span {
  color: var(--black);
  padding-left: 5px;
  display: none;
}
.modalProduct_recipe-arrow {
  font-size: 40px;
  transform: rotate(90deg);
  font-weight: 900;
  line-height: 0;
}
.modalProduct_product {
  width: 100%;
  margin: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  color: var(--black);
}
.modalProduct_product-name {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}
.modalProduct_product img {
  width: 30px;
  height: 30px;
}
.modalProduct_product-description {
  text-align: left;
}
.modalProduct_product-prices {
  display: flex;
}
.modalProduct_product-price {
  width: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  padding: 5px;
  text-align: center;
}
.modalProduct_product-price span {
  font-size: 11px;
  margin: 0;
}
.modalProduct_product-price.cinemas {
  background-color: var(--red);
  border-radius: 8px 0 0 8px;
  font-weight: 900;
}
.modalProduct_product-price.other {
  background-color: var(--black);
  color: var(--white);
  border-radius: 0 8px 8px 0;
  font-weight: 900;
}
.modalProduct_recipe-row {
  padding: 5px 15px;
}
.modalProduct_recipe-row:nth-child(2n) {
  background-color: var(--ligthGray);
}
.modalProduct_recipe-row-title {
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  border-bottom: 1px solid var(--red);
  margin-bottom: 0;
}
/* .modalProduct_product.opacity {
  opacity: 0.4;
} */
.modalProduct_footer-button {
  width: 100%;
  height: 40px;
  color: var(--red);
  border: 0;
  font-weight: 900;
  font-size: 18px;
  border-top: 1px solid var(--red);
  background-color: var(--white);
}
.modalProduct_footer-totals {
  width: 100%;
  background-color: var(--white);
  padding: 0 10px;
}
.modalProduct_footer-totals h3 {
  font-size: 15px;
  padding: 0 10px;
  margin: 0;
}
.modalProduct_footer-box-totals {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ligthGray);
  margin-bottom: 10px;
}
.modalProduct_footer-totals-item {
  width: calc(100% / 2 - 5px);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  border-radius: 15px;
  background-color: var(--white);
  padding: 10px;
}
.modalProduct_footer-totals-item strong {
  font-weight: 900;
  font-size: 16px;
  line-height: 14px;
  margin-top: 5px;
}
.modalProduct_footer-totals-item.cinemas {
  border: 2px solid var(--red);
  color: var(--red);
}
.modalProduct_footer-totals-item.others {
  border: 2px solid var(--black);
  color: var(--black);
}
.modalProduct_footer .confectionery_resume {
  margin-bottom: 10px;
}
.modalProduct_explanation {
  text-align: center;
  padding: 0 20px;
  font-size: 12px;
  margin-bottom: 5px;
}
.modalProduct_recipe-description {
  font-size: 14px;
  font-weight: normal;
  color: var(--black);
}
