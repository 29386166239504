.Room {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}
.Room_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  overflow: auto;
  scroll-snap-align: center;
  position: relative;
  z-index: 1;
}
.Room_screen {
  margin-top: 80px;
  width: 400px;
  height: 10px;
  background-color: #000000;
  position: relative;
  margin: 150px auto 0;
}
.Room_content-screen {
  border-bottom: 75px solid #cdcdcd;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Room_content-screen span {
  margin-top: 75px;
  font-weight: bold;
  font-size: 35px;
  color: var(--white);
}
.Room_row {
  display: flex;
  color: black;
}
.Room_chair,
.Room_hall {
  width: 35px;
  height: 45px;
  margin-bottom: 0px;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Room_chair {
  border-top: 1px solid var(--ligthGray);
  position: relative;
}
.Room_button {
  width: 25px;
  height: 25px;
  background-image: url('../../../assets/images/chair.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 25px;
  border: 0;
  background-color: transparent;
  position: relative;
  z-index: 1;
}
.Room_button:focus {
  outline: none;
}
.Room_button.selected {
  background-position: top center !important;
}
.Room_button.disabled {
  background-position: top left !important;
  z-index: 3;
}
.Room_arrows {
  width: calc(100% + 30px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: var(--red);
  margin-bottom: 10px;
  position: absolute;
  z-index: 0;
  align-items: center;
  left: -15px;
  opacity: 0.5;
}
.Room_popup {
  width: 100%;
  height: 100%;
  background-color: rgba(229, 0, 25, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.9);
  text-align: center;
  padding: 20px;
  font-size: 20px;
}
.Room_popup button {
  width: 50px;
  height: 50px;
  font-size: 40px;
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--white);
}
.Room_popup button:focus {
  outline: none;
}
.Room_button-block {
  width: 105px;
  height: 135px;
  background-color: var(--white);
  position: absolute;
  left: -35px;
  top: -45px;
  z-index: 2;
  opacity: 0.5;
}
.Room_button-block-left,
.Room_button-block-right {
  width: 35px;
  height: 45px;
  background-color: var(--white);
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0.5;
}
.Room_button-block-left {
  left: -70px;
}
.Room_button-block-right {
  right: -70px;
}
.Room_button-ligth-block {
  width: 105px;
  height: 45px;
  background-color: var(--white);
  position: absolute;
  left: -35px;
  top: 0;
  z-index: 2;
  opacity: 0.5;
}
.Room_button-ligth-block-top,
.Room_button-ligth-block-bottom {
  width: 35px;
  height: 45px;
  background-color: var(--white);
  position: absolute;
  left: 0;
  z-index: 2;
  opacity: 0.5;
}
.Room_button-ligth-block-top {
  top: -45px;
}
.Room_button-ligth-block-bottom {
  top: 45px;
}
.Room_chairname {
  position: relative;
}
.Room_chairname.blocked {
  z-index: 3;
}
.Room_button.opacity {
  opacity: 0.5;
}
