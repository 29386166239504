.Ticket_movie {
  width: 100%;
}
.Ticket_movie h2 {
  font-size: 20px;
  text-align: center;
}
.Ticket_movie h3 {
  font-size: 16px;
  text-align: center;
  max-width: 300px;
  margin: 10px auto 30px;
}
.Ticket_products table {
  max-width: 800px;
  margin: 0 auto;
}
.Ticket_products div h3 {
  font-size: 20px;
  margin-bottom: 10px;
}
.Ticket_box-qr {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  margin: 0 auto;
  background-color: var(--red);
  border-radius: 30px;
}
.Ticket_row-qr-white {
  width: 100%;
  padding: 25px;
  background-color: var(--white);
}
.Ticket_row-qr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Ticket_qr {
  width: 100%;
  height: auto;
}
.Ticket_row-image {
  width: 100%;
  max-width: 150px;
  margin: 20px;
}
.Ticket_confectionery {
  text-align: center;
  background-color: var(--ligthGray);
  width: 100%;
  height: auto;
  border-radius: 30px;
  padding: 20px;
  margin-top: 20px;
}
.Ticket_line {
  border-top: 2px dotted var(--red);
  margin: 20px 0;
}
.Ticket_qr-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Ticket_qr-description img {
  width: 100%;
  margin: 20px;
  max-width: 200px;
}
.Ticket_qr-description div {
  max-width: 600px;
}
