.SliderMovies_container {
  width: calc(60% - 50px);
  height: auto;
  margin-left: 30px;
  margin-bottom: 20px;
}
.SliderMovies_slider {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.SliderMovies_slide {
  padding: 0 10px 10px;
}
.SliderMovies_container .slick-dots li {
  width: 10px;
  height: 10px;
}
.SliderMovies_container .slick-dots li button:before {
  width: 10px;
  height: 10px;
  background: transparent;
  content: '';
  opacity: 1;
  transition: 0.1s all;
  border-radius: 50%;
  border: 1px solid var(--white);
}
.SliderMovies_container .slick-dots li:hover button:before {
  background: var(--red);
  border: 1px solid var(--red);
  opacity: 0.5;
}
.SliderMovies_container .slick-dots li.slick-active button:before {
  background: var(--red);
  border: 1px solid var(--red);
}
@media screen and (max-width: 769px) {
  .SliderMovies_container {
    width: calc(55% - 50px);
    margin-left: 30px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .SliderMovies_container {
    width: 80%;
    max-width: 400px;
    margin-left: 0;
  }
  .SliderMovies_slider .SliderMovies_slide {
    width: 50% !important;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 450px) {
  .SliderMovies_slider .SliderMovies_slide {
    width: 100% !important;
  }
}
