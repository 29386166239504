.CouponLegals {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: top;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all;
  z-index: 10;
  flex-direction: column;
}
.CouponLegals.visible {
  visibility: visible;
  opacity: 1;
}
.CouponLegals_content {
  width: 90%;
  height: 400px;
  max-width: 500px;
  margin-top: 30px;
  position: relative;
  padding: 25px;
  background-color: var(--white);
  border-radius: 20px;
}
.CouponLegals_close {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 0;
  color: var(--red);
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;
}
.CouponLegals_title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}
.CouponLegals_small {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
}
.CouponLegals_small h1,
.CouponLegals_small h2,
.CouponLegals_small h3,
.CouponLegals_small h4,
.CouponLegals_small h5,
.CouponLegals_small h6 {
  color: var(--red);
}
.CouponLegals_small h1 {
  font-size: 24px;
}
.CouponLegals_small h2 {
  font-size: 22px;
}
.CouponLegals_small h3 {
  font-size: 20px;
}
.CouponLegals_small h4 {
  font-size: 18px;
}
.CouponLegals_small h5 {
  font-size: 17px;
}
.CouponLegals_small h6 {
  font-size: 16px;
}
