.TheaterFooter_container {
  margin-bottom: 0;
}
.TheaterFooter_content {
  width: 100%;
  display: flex;
  margin-top: -30px;
}
.TheaterFooter_content img {
  width: auto;
  height: 60vh;
}
.TheaterFooter_map {
  flex: 1;
  height: 60vh;
}
.TheaterFooter_content iframe {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .TheaterFooter_content {
    flex-direction: column;
  }
  .TheaterFooter_content img {
    width: 100%;
    height: auto;
  }
  .TheaterFooter_map,
  .TheaterFooter_map iframe {
    height: 400px;
  }
}
