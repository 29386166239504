.Confectionery_header {
  font-weight: 900 !important;
  color: var(--white);
  font-size: 28px;
  padding: 20px 10px 0px;
  border-bottom: 1px solid var(--red);
  margin-bottom: 20px;
}
.confectionery_select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confectionery_title {
  font-weight: 900;
  color: var(--white);
  font-size: 30px;
  text-align: center;
}
.confectionery_select select {
  height: 40px;
  padding: 0 10px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 10px;
}
.confectionery_select button {
  background-color: var(--red);
  color: var(--white);
  padding: 0 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 0.25rem;
  font-weight: 900;
  transition: 0.2s all;
}
.confectionery_select button:hover {
  background-color: var(--white);
  color: var(--red);
}
.confectionery_products {
  padding: 20px 0;
}
.confectionery_products .col-md-12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.confectionery_product-title {
  font-weight: 900;
  font-size: 18px;
  border-bottom: 3px solid var(--red);
  margin-bottom: 15px;
  padding-right: 15px;
  color: var(--white);
}
.confectionery_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confectionery_loading img {
  width: 80px;
  height: 80px;
}
.confectionery_products .col-md-3,
.confectionery_products .col-sm-6 {
  margin-bottom: 30px;
}
.confectionery_product {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--orange);
  color: var(--white);
  border-radius: 20px;
  position: relative;
}
.confectionery_product img {
  width: 100%;
  height: auto;
  border-radius: 18px 18px 0 0;
}
.confectionery_product-name {
  font-weight: 700;
  padding: 10px 15px 30px;
  text-transform: uppercase;
  text-align: center;
}
.confectionery_product-name button {
  width: 120px;
  height: 40px;
  position: absolute;
  margin: 0 auto;
  bottom: -15px;
  left: 0;
  right: 0;
  background-color: var(--white);
  color: var(--red);
  border-radius: 0.25rem;
  border: 0;
  font-weight: bold;
  transition: 0.2s all;
}
.confectionery_product-name button:hover {
  color: var(--black);
  transform: translateY(-4px);
  box-shadow: 0 5px 2px var(--black);
}
.confectionery_resume {
  padding: 0 10px;
}
.confectionery_resume h3 {
  font-size: 15px;
  margin-bottom: 0;
}
.confectionery_resume-row {
  font-size: 13px;
}
