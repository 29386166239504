.Payment_resume {
  width: 100%;
  max-width: 500px;
  background-color: var(--ligthGray);
  color: var(--gray);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Payment_prices {
  width: 100%;
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.Payment_price-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Payment_price-total {
  font-size: 22px;
}
.Payment_button {
  width: 100%;
  max-width: 300px;
  height: 40px;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0;
  margin-top: 20px;
  transition: 0.2s all;
}
.Payment_button strong {
  margin-left: 5px;
}
.Payment_button:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}
.Payment_button img {
  width: 60px;
  margin-left: 5px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.Payment_cinema {
  width: 100%;
  text-align: left;
}
.Payment_cinema strong {
  color: var(--red);
}
.Payment_price-other {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #cdcdcd;
}
.PaymentMethods_box-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
}
.PaymentMethods_box-loading img {
  width: 100px;
  margin-bottom: -15px;
}
.Payment_face-mask {
  margin-top: 10px;
}
.Payment_face-mask label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.Payment_face-mask label input {
  margin-right: 5px;
  margin-top: 5px;
}
.PaymentMethods_combos .table th,
.PaymentMethods_combos .table td {
  padding: 0.5rem;
}
.PaymentMethods_total {
  font-weight: bold;
}
.PaymentMethods_total-number {
  font-weight: 900;
  color: var(--red);
  font-size: 18px;
}
.PaymentMethods_combos .table {
  margin-bottom: 0;
}
.Payment_payment {
  display: flex;
  justify-content: flex-end;
}
