.TheaterMovies_container {
  margin-bottom: 20px;
}
.TheaterMovies_container h3 {
  text-align: center;
}
.TheaterMovies_date-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.TheaterMovies_date {
  margin: 0 10px 10px;
  padding-bottom: 5px;
}
.TheaterMovies_date.selected {
  border-bottom: 2px solid var(--red);
}
.TheaterMovies_date button {
  width: 120px;
  height: 35px;
  border-radius: 5px 5px 10px 10px;
  background-color: var(--red);
  color: var(--white);
  transition: 0.2s all;
  border: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.TheaterMovies_date button:hover {
  background-color: var(--black);
}
.TheaterMovies_name {
  padding: 5px 10px;
  background-color: var(--ligthGray);
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}
.TheaterMovies_showtimes h4 {
  font-size: 20px;
  color: var(--red);
}
.TheaterMovies_showtimes-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.TheaterMovies_showtimes-list button,
.TheaterMovies_showtimes-list a,
.TheaterMovies_showtimes-list .button {
  width: 70px;
  height: 30px;
  background-color: var(--red);
  color: var(--white);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 5px 0;
  border-radius: 10px;
  transition: 0.2s all;
  border: 0;
}
.TheaterMovies_showtimes-list button:hover,
.TheaterMovies_showtimes-list a:hover {
  background-color: var(--black);
  text-decoration-line: none;
  color: var(--white);
}
.TheaterMovies_showtimes-list button:focus,
.TheaterMovies_showtimes-list a:focus,
.TheaterMovies_date button:focus {
  outline: none;
}
.TheaterMovies_container .col-sm-6.col-md-4 {
  margin-bottom: 30px;
}
