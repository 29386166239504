.MovieTheater h2 {
  color: var(--red);
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
}
.MovieTheater div div label img {
  height: 30px;
  margin: 0px 5px;
}
.MovieTheater div div label span {
  margin: 0px 5px;
}
.datepick {
  text-align: center;
  font-weight: bold;
  background-color: var(--red);
  border: 1px solid var(--red);
  color: #fff;
  width: 230px;
}
.MovieTheater_container {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--white);
  margin-bottom: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}
.MovieTheater_container h4 {
  text-align: center;
}
.MovieTheater_subtitle {
  width: 100%;
  padding: 5px 20px;
  background-color: var(--ligthGray);
  color: var(--gray);
  margin: 10px 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
}
.MovieTheater_options {
  margin-bottom: 20px;
}
.MovieTheater_hours {
  display: flex;
  flex-wrap: wrap;
}
.MovieTheater_hours a,
.MovieTheater_hours button,
.MovieTheater_hours .button {
  width: 90px;
  background-color: var(--red);
  border: 0;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  text-transform: uppercase;
  border-radius: 5px;
  transition: 0.2s all;
  line-height: 0;
  margin: 0 10px 10px 0;
}
.MovieTheater_hours a:hover,
.MovieTheater_hours button:hover {
  background-color: var(--gray);
  color: var(--white);
  text-decoration: none;
}
.MovieTheater .container h4 {
  text-align: center;
  color: var(--red);
}
