.Cinemas-container {
  width: 100%;
  height: auto;
  background: url('../../assets/images/cinemas/background.jpg') center center
    no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 40px;
}
.Cinemas-container h1 {
  display: none;
}
.Cinemas-content {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Cinemas-logo {
  width: 100%;
}
.Cinemas-text01 {
  width: 100%;
  max-width: 700px;
}
.Cinemas-text02 {
  width: 100%;
  max-width: 400px;
  margin-top: -15%;
}
.Cinemas-text03 {
  width: 100%;
  max-width: 500px;
  padding-bottom: 40px;
}
.Cinemas-sections {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  text-align: center;
}
.Cinemas-section {
  width: calc(100% / 3);
  padding: 0 20px;
}
.Cinemas-step {
  background-color: transparent;
  border: 0;
}
.Cinemas-step img {
  width: auto;
  height: 80px;
}
.Cinemas-image img {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}
@media screen and (max-width: 900px) {
  .Cinemas-step img {
    height: 60px;
  }
}
@media screen and (max-width: 700px) {
  .Cinemas-sections {
    flex-direction: column;
  }
  .Cinemas-section {
    width: 100%;
    padding: 0 0;
  }
  .Cinemas-section img {
    margin-bottom: 20px;
  }
  .Cinemas-image img {
    width: 100%;
    max-width: 300px;
    margin-top: -30px;
  }
}
