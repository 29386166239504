.NextReleases_container {
  padding: 20px 0 50px;
}
.NextReleases_container .col-sm-6.col-md-3 {
  margin-bottom: 30px;
}
.NextReleases_button {
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  color: var(--red);
  margin: 0 auto;
  font-weight: bold;
  border-radius: 10px;
  transition: 0.2s all;
}
.NextReleases_button:hover {
  background-color: var(--black);
  color: var(--white);
  text-decoration: none;
}
