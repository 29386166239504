.HowToBuy_container {
  padding: 30px;
  background-color: var(--white);
  text-align: center;
}
.HowToBuy_title {
  color: var(--red);
}
.HowToBuy_description {
  width: 90%;
  max-width: 500px;
  color: var(--mediumGray);
  display: block;
  margin: 0 auto 30px;
}
.HowToBuy_steps {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  color: var(--red);
  font-size: 14px;
}
.HowToBuy_step {
  width: 120px;
  margin: 0 8px;
}
.HowToBuy_number {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--red);
  display: block;
  margin: 0 auto 5px;
  border-radius: 50%;
  font-size: 20px;
}
.HowToBuy_step i {
  color: var(--black);
  font-size: 80px;
}
.HowToBuy_step p {
  margin: 5px 0 0;
  line-height: 16px;
}
