.combos_row {
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--handGray);
}
.combos_row img {
  width: 40px;
  display: block;
  height: auto;
  margin-right: 5px;
}
.combos_description {
  margin-left: -10px;
  margin-right: -10px;
}
.combos_text h3 {
  font-size: 14px;
  margin-bottom: 0;
}
.combos_text h2 {
  font-size: 16px;
  margin-bottom: 0;
}
.combos_actions {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.combos_remove {
  color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  transition: 0.2s all;
  font-size: 20px;
  margin-bottom: 10px;
}
.combos_remove:hover {
  color: var(--redHover);
}
.combos_amout {
  display: flex;
  justify-content: center;
  align-items: center;
}
.combos_amout-button {
  width: 28px;
  height: 36px;
  background-color: var(--handGray);
  border: 0;
}
.combos_amout-button.red {
  background-color: var(--red);
  color: var(--white);
}
.combos_text-amout {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid var(--handGray);
  font-size: 14px;
  background-color: var(--white);
}
.combos_amout-remove {
  border-radius: 18px 0 0 18px;
}
.combos_amout-add {
  border-radius: 0 18px 18px 0;
}
.combos_title {
  font-size: 22px;
}
