:root {
  --red: #e50019;
  --redHover: #a51425;
  --black: #000000;
  --white: #ffffff;
  --darkGray: rgba(40, 40, 39, 1);
  --darkRed: rgba(105, 44, 44, 1);
  --gray: rgb(40, 40, 40);
  --mediumGray: #969696;
  --handGray: #b9b9b9;
  --ligthGray: #f0f3f7;
  --alphaWhite: rgba(255, 255, 255, 0.55);
  --orange: #e57200;
  --background: #f4f4f4;
  --grayText: #707070;
  --blue: #236591;
  --modal: rgba(0, 0, 0, 0.85);
}
button:focus {
  outline: none;
}
.vertical-gradient {
  background: var(--darkGray);
  background: linear-gradient(180deg, var(--darkGray) 0%, var(--darkRed) 100%);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif !important;
  background-color: var(--background);
}
.strong {
  font-family: 'source_sans_probold';
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900 !important;
}
.row {
  min-width: 100%;
}
.Intro {
  width: 100%;
  height: 100%;
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Into_box-image {
  width: 280px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Intro img {
  width: 100%;
  height: auto;
}
.color-red {
  color: var(--red);
}
.swal-button.swal-button--confirm,
.btn-red {
  background-color: var(--red);
  transition: 0.2s all;
  color: var(--white);
  font-weight: bold;
}
.swal-button.swal-button--confirm:hover,
.btn-red:hover {
  background-color: var(--redHover);
  color: var(--white);
}
.form-label {
  margin-bottom: 0;
}
.Intro_text {
  width: 90%;
  max-width: 500px;
  font-size: 20px;
  text-align: center;
  color: var(--white);
}
.btn-procinal {
  background-color: var(--red);
  color: var(--white);
}
.btn-procinal:hover {
  background-color: var(--black);
  color: var(--white);
}
.mb-10 {
  margin-bottom: 10px;
}
.btn-black {
  background-color: var(--black);
  color: var(--white) !important;
}
.btn-black:hover {
  color: var(--red) !important;
}
.color-white {
  color: var(--white);
}
.cine_day {
  text-align: center;
  width: 80%;
  margin: 0 auto 20px;
}
