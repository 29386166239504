.HomeMovieSlider_container {
  width: 100%;
  height: 276px;
  overflow: hidden;
  margin-top: 20px;
  position: relative;
}
.HomeMovieSlider_row {
  display: flex;
  position: absolute;
  top: 0;
}
.HomeMovieSlider_image {
  position: absolute;
  top: 0;
}
.HomeMovieSlider_image img {
  width: 200px;
  height: auto;
  margin: 0 3px;
  border-radius: 10px;
}
