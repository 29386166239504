.HowToBuyImax-container {
  width: 100%;
  padding: 30px 0;
  background-image: url('../../assets/images/imax/footer.jpg');
  background-size: cover;
  background-position: right bottom;
  margin-bottom: 30px;
}
.HowToBuyImax-container h1 {
  color: var(--white);
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
}
.HowToBuyImax-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.HowToBuyImax-step {
  width: 100%;
  max-width: 200px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  margin-bottom: 20px;
}
.HowToBuyImax-step img {
  width: 100px;
  margin-bottom: 10px;
}
.HowToBuyImax-footer {
  text-align: center;
  position: relative;
  padding-bottom: 50px;
}
.HowToBuyImax-footer h2 {
  color: #0a71ce;
  font-size: 40px;
}
.HowToBuyImax-footer h3 {
  text-align: center;
  margin: 0 auto;
  color: var(--white);
  max-width: 800px;
}
.HowToBuyImax-footer img {
  width: 150px;
  height: auto;
  position: absolute;
  right: 3%;
  bottom: 0;
}
@media screen and (max-width: 768px) {
  .HowToBuyImax-footer {
    padding-bottom: 0;
  }
  .HowToBuyImax-footer img {
    position: initial;
  }
}
