.Register {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.8s all;
  visibility: hidden;
  opacity: 0;
}
.Register.visible {
  visibility: visible;
  opacity: 1;
  overflow: auto;
}
.Register-container {
  width: 90%;
  max-width: 750px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--white);
  margin: 20px auto;
  position: relative;
  animation: modalOut 0.8s forwards;
  overflow: auto;
}
.Register-container .row .small {
  font-size: 12px;
  line-height: 10px;
}
.Register.visible .Register-container {
  animation: modalIn 0.8s forwards;
}
.Register .Register-container button:first-child {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  z-index: 1;
  border: 0;
  background-color: transparent;
  font-size: 20px;
}
.Register .Register-container button:first-child:focus {
  outline: none;
}
.Register .Register-container label span {
  color: var(--red);
  font-size: 14px;
}
.input-document input {
  margin-left: 10px;
}
.Register .Register-container label input:focus,
.Register .Register-container label select:focus {
  outline: none;
}
.Register_button {
  width: 200px;
  height: 40px;
  background-color: var(--red);
  color: var(--white);
  display: block;
  margin: 20px auto 0;
  border: 0;
  transition: 0.3s all;
  cursor: pointer;
  font-size: 16px;
}
.Register_button:hover {
  background-color: rgb(40, 40, 40);
}
.input-document {
  display: flex;
}
.Register_policies a {
  color: var(--red);
}
.Register_policies a:hover {
  color: var(--red);
}

@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}

@media screen and (max-width: 768px) {
  .Register {
    overflow: auto;
  }
}
