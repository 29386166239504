.Status {
  margin: 30px;
}
.Status_container {
  width: 100%;
  background-color: var(--red);
  padding: 20px;
  border-radius: 20px;
}
.Status_container h2 {
  color: var(--white);
  text-align: center;
}
.Status_content {
  width: 100%;
  max-width: 800px;
  padding: 20px 80px;
  border-radius: 20px;
  background-color: var(--white);
  margin: auto;
}
.Status_content img {
  width: 100px;
  margin: 0 auto;
  display: block;
}
.Status_header {
  text-align: center;
  margin-bottom: 30px;
}
.Status_header h3,
.Status_body h3 {
  color: var(--red);
}
.Status_body {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--gray);
}
.Status_footer {
  text-align: right;
}
.Status_footer {
  font-size: 30px;
  font-weight: 700;
}
.Status_footer span {
  color: var(--red);
}
.Status_tickets {
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: var(--red);
  color: var(--white);
  transition: 0.2s all;
}
.Status_tickets:hover {
  background-color: var(--gray);
  color: var(--white);
  text-decoration: none;
}
.Status_codes {
  display: flex;
}
.Status_codes-row {
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .Status_content {
    padding: 20px;
  }
}
