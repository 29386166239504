.MovieBox {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
  margin-bottom: 15px;
  display: block;
  transition: 0.2s all;
}
.MovieBox.red {
  background-color: var(--red);
}
.MovieBox.orange {
  background-color: var(--orange);
}
.MovieBox.white {
  background-color: var(--white);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.MovieBox:hover {
  text-decoration: none;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.75);
}
.MovieBox img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  position: relative;
  z-index: 4;
  margin-bottom: -15px;
}
.MovieBox .MovieBox_title {
  width: 100%;
  height: auto;
  background-color: var(--black);
  color: var(--white);
  padding: 25px 10px 10px;
  border-radius: 0 0 15px 15px;
  text-align: center;
  position: relative;
  z-index: 3;
  margin-bottom: -15px;
}
.MovieBox .MovieBox_title h3 {
  font-size: 18px;
  margin: 0;
}
.MovieBox .MovieBox_title p {
  margin-bottom: 0;
  font-size: 14px;
}
.MovieBox .MovieBox_type,
.MovieBox .MovieBox_date {
  padding-top: 20px;
  padding-bottom: 10px;
  color: var(--white);
  text-align: center;
  border-radius: 0 0 15px 15px;
  position: relative;
  z-index: 2;
  font-weight: bold;
  margin-bottom: -15px;
  font-size: 14px;
}
.MovieBox .MovieBox_date {
  font-weight: normal;
}
.MovieBox_type.red,
.MovieBox_date.red {
  background-color: var(--red);
}
.MovieBox_type.orange {
  background-color: var(--orange);
}
.MovieBox .MovieBox_date {
  z-index: 1;
}
.MovieBox_content {
  background-color: var(--white);
  padding: 20px 10px 10px;
  border-radius: 0 0 10px 10px;
  margin-bottom: -15px;
}
/*=================== SMALL ===================*/
.MovieBox.small {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}
.MovieBox.small img {
  border-radius: 8px;
  margin-bottom: -8px;
}
.MovieBox.small .MovieBox_title {
  border-radius: 0 0 8px 8px;
  padding-top: 20px;
  margin-bottom: -8px;
}
.MovieBox.small .MovieBox_title h3 {
  font-size: 14px;
}
.MovieBox.small .MovieBox_title p {
  font-size: 10px;
}
.MovieBox.small .MovieBox_type {
  margin-bottom: -8px;
}
.MovieBox.small .MovieBox_type,
.MovieBox.small .MovieBox_date {
  padding-bottom: 8px;
  padding-top: 12px;
  border-radius: 0 0 8px 8px;
  font-size: 10px;
}
