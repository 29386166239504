.MyCoupon_image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.MyCoupon_content {
  width: 100%;
}
.MyCoupon_detail h5 {
  color: var(--red);
}
.MyCoupon_detail .small h1 {
  font-size: 20px;
}
.MyCoupon_legals {
  color: #000000 !important;
  background-color: transparent !important;
  width: 200px;
  font-size: 12px;
  display: block;
  margin: 20px auto 0;
  border: 0;
}
.MyCoupon_legals:focus {
  outline: none;
}
.MyCoupon_legals:hover {
  text-decoration: underline;
}
.MyCoupon_codes {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto 20px;
}
.MyCoupon_code {
  border-bottom: 1px solid #cdcdcd;
  text-align: center;
  padding-bottom: 15px;
  margin: 0 10px;
}
.MyCoupon_codes h5 {
  margin: 0;
}
.MyCoupon_code-image {
  display: block;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
  .MyCoupon_image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .MyCoupon_content {
    width: 100%;
  }
}
