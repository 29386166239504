.CouponPayment_resume {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.CouponPayment_resume h4 {
  text-align: center;
}
.CouponPayment_box {
  width: 100%;
  max-width: 500px;
  background-color: var(--ligthGray);
  color: #000000;
  border-radius: 20px;
  padding: 20px;
}
.CouponPayment_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.CouponPayment_box-couter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.CouponPayment_box-couter button {
  width: 35px;
  height: 35px;
  background-color: var(--red);
  color: var(--white);
  border: 0;
}
.CouponPayment_couter {
  width: 40px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
  background-color: var(--white);
}
.CouponPayment_total {
  border-top: 1px solid #cdcdcd;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}
.CouponPayment_button {
  width: 100%;
  max-width: 300px;
  height: 40px;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0;
  margin: 0 auto;
  transition: 0.2s all;
}
.CouponPayment_button img {
  width: auto !important;
  height: 20px;
  margin: 0 0 0 5px !important;
}
.CouponPayment_button:hover {
  box-shadow: 0 3px 5px rgb(0 0 0 / 50%);
}
.CouponPayment_button:focus {
  outline: none;
}
