.SelectCity_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 40px;
}
.SelectCity_item {
  position: relative;
}
.SelectCity_item .hand {
  position: absolute;
  bottom: 35px;
  right: 0;
  margin-right: 0;
}
.SelectCity_container button {
  width: 130px;
  height: 35px;
  background-color: var(--black);
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px 5px;
  color: var(--white);
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  transition: 0.2s all;
}
.SelectCity_container button .small {
  font-size: 10px;
}
.SelectCity_container button:hover {
  color: var(--black);
  background-color: var(--white);
}
.SelectCity_container button:focus {
  outline: none;
}
@media screen and (max-width: 420px) {
  .SelectCity_container {
    padding-top: 20px;
  }
  .SelectCity_item .hand {
    font-size: 30px;
    right: -5px;
    bottom: 15px;
  }
}
