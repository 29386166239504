.TheaterList_container {
  background-color: var(--background);
  display: block;
}
.TheaterList_cinemas {
  padding-top: 30px;
}
.TheaterList_cinema {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  color: var(--white);
  background-color: transparent;
  border-radius: 0 0 12px 12px;
  transition: 0.2s all;
}
.TheaterList_cinema:hover {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
  text-decoration: none;
  color: var(--white);
}
.TheaterList_cinema img {
  width: 100%;
}
.TheaterList_name {
  width: 100%;
  text-align: center;
  background-color: var(--black);
  padding: 10px;
  border-radius: 0 0 12px 12px;
  margin-bottom: -12px;
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 18px;
}
.TheaterList_address {
  width: 100%;
  text-align: center;
  padding: 20px 10px 8px;
  background-color: var(--red);
  border-radius: 0 0 12px 12px;
  font-size: 14px;
}
.TheaterList_link {
  width: 180px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--red);
  color: var(--white);
  margin: 20px auto 50px;
  border-radius: 10px;
  transition: 0.2s all;
}
.TheaterList_link:hover {
  background-color: var(--black);
  text-decoration: none;
  color: var(--white);
}
