.CorporativeHeader-content {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.CorporativeHeader-content a {
  display: block;
  margin: 5px 10px;
  text-decoration: none;
}
.CorporativeHeader-content a:hover {
  text-decoration: none;
}
.CorporativeHeader-link {
  width: 170px;
  height: 45px;
  background-color: var(--red);
  color: var(--white);
  transition: 0.2s all;
  text-align: center;
  line-height: 18px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 5px 5px 10px 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}
.CorporativeHeader-content a:hover .CorporativeHeader-link {
  background-color: var(--black);
}
.CorporativeHeader-active {
  width: 100%;
  height: 4px;
  margin-top: 10px;
}
.CorporativeHeader-content a.active .CorporativeHeader-active {
  background-color: var(--red);
}
