.Login {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: 0.8s all;
}

.Login.visible {
  opacity: 1;
  visibility: visible;
}

.Login_container {
  width: 90%;
  max-width: 750px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--white);
  margin: 20px auto;
  position: relative;
  overflow: auto;
  animation: modalOut 0.8s forwards;
}
.Login.visible .Login_container {
  animation: modalIn 0.8s forwards;
}

.Login .Login_container,
.Login .Login_container label {
  display: flex;
  flex-direction: column;
}

.Login .Login_container button:first-child {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  z-index: 1;
  border: 0;
  background-color: transparent;
  font-size: 20px;
}

.Login .Login_container button:first-child:focus {
  outline: none;
}

.Login .Login_container label span {
  color: var(--red);
  font-size: 14px;
}

.Login .Login_container label input {
  margin-bottom: 10px;
}

.Login input:focus {
  outline: none;
}

.Login-pass-fogotten {
  text-align: right;
  cursor: pointer;
  height: 25px;
  font-size: 16px;
  border: 0;
  background-color: transparent;
  margin-bottom: 10px;
}
.Login-pass-fogotten:focus {
  outline: none;
}

.Login_button {
  width: 220px;
  height: 40px;
  background-color: var(--red);
  color: var(--white);
  display: block;
  margin: 0 auto;
  border: 0;
  transition: 0.3s all;
  cursor: pointer;
  font-size: 16px;
}
.Login .Login_container button:last-child:hover {
  background-color: rgb(40, 40, 40);
}
.Login_account {
  text-align: center;
  margin-top: 20px;
}
.Login_account span {
  text-decoration: underline;
  cursor: pointer;
}

@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}

@media screen and (max-width: 768px) {
  .Login {
    overflow: auto;
  }
}
