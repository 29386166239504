.TheaterSchedule_container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
}
.TheaterSchedule_container h2 {
  margin-bottom: 40px;
}
.TheaterSchedule_content {
  width: 100%;
  border-radius: 5px 5px 15px 15px;
}
.TheaterSchedule_header {
  width: 100%;
  height: auto;
  overflow: auto;
}
.TheaterSchedule_menu {
  display: flex;
}
.TheaterSchedule_button {
  width: 190px;
  min-width: 190px;
  height: 45px;
  border: 0;
  background-color: var(--white);
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  line-height: 18px;
  color: var(--red);
  font-weight: bold;
  border: 1px solid var(--handGray);
  border-left: transparent;
  border-bottom: transparent;
}
.TheaterSchedule_button:first-child {
  border-left: 1px solid var(--handGray);
}
.TheaterSchedule_button.active {
  background-color: var(--black);
  color: var(--white);
}
.TheaterSchedule_button:focus {
  outline: none;
}
.TheaterSchedule_container h3 {
  color: var(--red);
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  margin-top: 15px;
}
.TheaterSchedule_table {
  padding: 10px;
  color: var(--white);
}
.TheaterSchedule_body {
  background-color: var(--red);
  border-radius: 0 0 15px 15px;
}
.TheaterSchedule_table table {
  width: 100%;
}
.TheaterSchedule_table table thead tr th {
  padding: 10px 0;
  text-decoration: underline;
}
.TheaterSchedule_table table tbody tr td:nth-child(1) {
  text-align: left;
  padding-left: 10px;
}
@media screen and (max-width: 500px) {
  .TheaterSchedule_container {
    width: 100%;
    padding: 0 20px;
  }
  .TheaterSchedule_button {
    width: 100px;
    font-size: 10px;
  }
  .TheaterSchedule_table table {
    border: 1px solid var(--white);
    font-size: 12px;
  }
}
