@font-face {
  font-family: 'source_sans_probold';
  src: url('../fonts/sourcesanspro-bold-webfont.eot');
  src: url('../fonts/sourcesanspro-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sourcesanspro-bold-webfont.woff2') format('woff2'),
    url('../fonts/sourcesanspro-bold-webfont.woff') format('woff'),
    url('../fonts/sourcesanspro-bold-webfont.ttf') format('truetype'),
    url('../fonts/sourcesanspro-bold-webfont.svg#source_sans_probold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'source_sans_prolight';
  src: url('../fonts/sourcesanspro-light-webfont.eot');
  src: url('../fonts/sourcesanspro-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sourcesanspro-light-webfont.woff2') format('woff2'),
    url('../fonts/sourcesanspro-light-webfont.woff') format('woff'),
    url('../fonts/sourcesanspro-light-webfont.ttf') format('truetype'),
    url('../fonts/sourcesanspro-light-webfont.svg#source_sans_prolight')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'source_sans_proregular';
  src: url('../fonts/sourcesanspro-regular-webfont.eot');
  src: url('../fonts/sourcesanspro-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
    url('../fonts/sourcesanspro-regular-webfont.woff') format('woff'),
    url('../fonts/sourcesanspro-regular-webfont.ttf') format('truetype'),
    url('../fonts/sourcesanspro-regular-webfont.svg#source_sans_proregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
