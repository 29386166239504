.MovieListTitle {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
}
.MovieListTitle h2:first-child {
  color: var(--black);
}
.MovieListTitle h2 {
  font-size: 50px;
  color: var(--red);
  line-height: 34px;
  font-weight: 700 !important;
}
.MovieListTitle.small h2 {
  font-size: 37px;
  line-height: 30px;
}
.MovieListTitle p {
  color: var(--mediumGray);
  font-weight: lighter;
  font-size: 20px;
}
@media screen and (max-width: 700px) {
  .MovieListTitle h2 {
    font-size: 40px;
    line-height: 28px;
  }
  .MovieListTitle p {
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .MovieListTitle h2 {
    font-size: 30px;
    line-height: 24px;
  }
  .MovieListTitle p {
    font-size: 16px;
  }
}
@media screen and (max-width: 420px) {
  .MovieListTitle h2 {
    font-size: 25px;
    line-height: 20px;
  }
  .MovieListTitle p {
    font-size: 14px;
  }
}
@media screen and (max-width: 330px) {
  .MovieListTitle h2 {
    font-size: 23px;
    line-height: 19px;
  }
}
