.RechargeHeader-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.RechargeHeader-container button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 5px solid var(--black);
  color: var(--white);
  background-color: var(--red);
  transition: 0.2s all;
  margin-left: 10px;
}
.RechargeHeader-container button:hover {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.RechargeHeader-box {
  width: 100%;
  max-width: 250px;
  background-color: var(--black);
  color: var(--white);
  padding: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.RechargeHeader-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.RechargeHeader-number {
  width: 100%;
  border-bottom: 1px solid var(--red);
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.RechargeHeader-price {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.RechargeHeader-price img {
  width: 40px;
}
