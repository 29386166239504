.SliderPublish {
  width: calc(40% - 20px);
  position: relative;
  padding: 0 35px;
  margin-bottom: 20px;
}
.SliderPublish-slider {
  width: 100%;
}
.SliderPublish-slider img {
  width: 100%;
}
.SliderPublish_button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: transparent;
  border: 2px solid var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: 0;
  color: var(--white);
  position: absolute;
  margin: 0 auto;
  top: calc(50% - 15px);
}
.SliderPublish_button:focus {
  outline: none;
}
.SliderPublish_prev {
  left: -35px;
}
.SliderPublish_next {
  right: -35px;
}
@media screen and (max-width: 1279px) {
  .SliderPublish {
    max-width: 400px;
  }
}
@media screen and (max-width: 769px) {
  .SliderPublish {
    width: calc(45% - 20px);
  }
}
@media screen and (max-width: 600px) {
  .SliderPublish {
    width: 100%;
  }
}
