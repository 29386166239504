.cart_button {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border: 2px solid var(--white);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.75);
  border-radius: 50%;
}
.cart_button-circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  color: var(--white);
  font-size: 10px;
  position: absolute;
  top: -10px;
  right: -10px;
}
.cart_resume {
  width: 300px;
  height: 100%;
  background-color: var(--white);
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 6;
  transition: 0.3s all;
}
.cart_resume.show {
  right: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
}
.cart_resume-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: auto;
}
.cart_resume-content {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.cart_resume-header {
  width: 100%;
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px solid red;
  margin-bottom: 15px;
}
.cart_resume-header h2 {
  font-size: 18px;
}
.cart_resume-body {
  padding: 0 15px 0;
}
.cart_resume-body .confectionery_resume-row {
  font-size: 12px;
  line-height: 14px;
}
.cart_resume-close {
  width: 35px;
  height: 35px;
  background-color: var(--red);
  color: var(--white);
  transition: 0.2s all;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.cart_resume-close:hover {
  background-color: var(--black);
}
.cart_resume-footer {
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 0 10px rgb(0 0 0 / 75%);
  border-radius: 15px 15px 0 0;
  padding: 10px;
}
.cart_resume-footer .Checkout_box-selected {
  padding: 5px;
}
.cart_resume-footer .Checkout_image {
  display: none;
}
.cart_resume-footer h5 {
  font-size: 16px;
}
.cart_resume-footer .Checkout_location {
  width: calc(100% / 2 - 20px);
  height: 70px;
  font-size: 16px;
}
.cart_resume-total-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  color: var(--black);
}
.cart_resume-total-title {
  font-weight: bold;
}
.cart_resume-total {
  font-size: 16px;
  padding: 10px 0;
  margin: 5px 0;
  color: var(--red);
  border-top: 1px solid var(--handGray);
  border-bottom: 1px solid var(--handGray);
}
.cart_resume-total .cart_resume-total-price {
  font-weight: 900;
}
.cart_resume-pay {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: var(--red);
  color: var(--white);
  transition: 0.2s all;
  border: 0;
  margin-top: 5px;
}
.cart_resume-pay:hover {
  background-color: var(--redHover);
}
.cart_resume-pay img {
  width: 60px;
  height: auto;
  border-left: 5px;
}
