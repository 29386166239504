.PopUp-contaniner {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
  padding-bottom: 30px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
}
.PopUp-contaniner.visible {
  opacity: 1;
  visibility: hidden;
}
.PopUp-content {
  width: 90%;
  position: relative;
  padding-top: 30px;
}
.PopUp-content img {
  width: 100%;
}
.PopUp-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -20px;
  top: 10px;
  border: 2px solid var(--white);
  font-size: 24px;
  transition: 0.2s all;
}
.PopUp-button:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.75);
}
.PopUp-button:focus {
  outline: none;
}
