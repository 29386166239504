.Coupons_content {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.65);
  padding: 20px;
  border-radius: 20px;
}
.Coupons_content h1 {
  text-align: center;
  color: var(--red);
  font-size: 25px;
}
.Coupons_item {
  position: relative;
  margin-bottom: 20px;
}
.Coupons_item img {
  width: 100%;
}
.Coupons_hover {
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
}
.Coupons_hover a {
  width: 100px;
  height: 40px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--red);
  color: var(--white);
  margin-bottom: 5px;
  transition: 0.2s all;
}
.Coupons_item:hover .Coupons_hover {
  height: 100%;
}
.Coupons_item:hover a {
  visibility: visible;
  opacity: 1;
}
