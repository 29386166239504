.Benefits_container {
  position: relative;
  padding: 70px 0;
}
.Benefits_title {
  width: 90%;
  max-width: 500px;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: var(--red);
  color: var(--white);
  font-weight: bold;
  font-size: 25px;
  border-radius: 5px 5px 15px 15px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}
.Benefits_content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
}
.Benefits_item {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin: 20px 0;
}
.Benefits_item i {
  color: var(--red);
  font-size: 180px;
}
.Benefits_item-title {
  font-size: 22px;
  line-height: 20px;
}
.Benefits_item-link {
  width: 150px;
  height: 30px;
  background-color: var(--black);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-size: 14px;
  border-radius: 5px 5px 10px 10px;
  transition: 0.2s all;
}
.Benefits_item-link:hover {
  background-color: var(--red);
  color: var(--white);
  text-decoration: none;
}
.Benefits_item-percentage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50px;
  top: 30%;
  font-size: 14px;
}
