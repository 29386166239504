.MakeByBeit {
  width: 100%;
  background: #000000;
  padding: 5px 20px;
  font-size: 10px;
  color: var(--white);
  text-align: center;
}

.MakeByBeit a {
  color: var(--white);
}

.MakeByBeit a:hover {
  color: var(--white);
}
