.ImaxMovies_container {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--red);
}
.ImaxMovies_container .vertical-gradient {
  padding-bottom: 20px;
}
.ImaxMovies_title {
  padding-top: 10px;
  background-color: var(--darkGray);
}
.ImaxMovies_container h3 {
  max-width: 750px;
  text-align: center;
  color: var(--white);
  font-size: 28px;
  margin-top: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.ImaxMovies_date-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.ImaxMovies_date {
  margin: 0 10px 10px;
  padding-bottom: 5px;
}
.ImaxMovies_date.selected {
  border-bottom: 2px solid var(--black);
  color: var(--red);
}
.ImaxMovies_date.selected button {
  background-color: var(--black);
  color: var(--white);
}
.ImaxMovies_date button {
  width: 120px;
  height: 35px;
  border-radius: 5px 5px 10px 10px;
  background-color: var(--white);
  color: var(--black);
  transition: 0.2s all;
  border: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.ImaxMovies_date button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white);
}
.ImaxMovies_item {
  width: 100%;
  height: 100%;
  background-color: var(--red);
  padding-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}
.ImaxMovies_item img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.ImaxMovies_item-title {
  text-align: center;
  border-bottom: 1px solid var(--red);
  background-color: var(--black);
  padding: 10px 5px;
  color: var(--white);
}
.ImaxMovies_item-title h3 {
  margin: 0;
  font-size: 20px;
}
.ImaxMovies_item-title p {
  margin: 0;
  font-size: 14px;
}
.ImaxMovies_item-description {
  padding: 20px;
  background-color: var(--black);
  color: var(--white);
  font-size: 14px;
  text-align: center;
}
.ImaxMovies_name {
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--white);
}
.ImaxMovies_showtimes h4 {
  font-size: 20px;
  color: var(--red);
  color: var(--white);
}
.ImaxMovies_showtimes-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ImaxMovies_showtimes-list button,
.ImaxMovies_showtimes-list a,
.ImaxMovies_showtimes-list .button {
  width: 70px;
  height: 30px;
  background-color: var(--white);
  color: var(--red);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 5px 0;
  border-radius: 10px;
  transition: 0.2s all;
  border: 0;
}
.ImaxMovies_showtimes-list button:hover,
.ImaxMovies_showtimes-list a:hover {
  background-color: var(--black);
  text-decoration-line: none;
  color: var(--white);
}
.ImaxMovies_showtimes-list button:focus,
.ImaxMovies_showtimes-list a:focus,
.ImaxMovies_date button:focus {
  outline: none;
}
.ImaxMovies_container .col-sm-6.col-md-4 {
  margin-bottom: 30px;
}
.ImaxMovies_billboard {
  width: 220px;
  font-weight: bold;
  text-align: center;
  padding: 3px 10px;
  background-color: var(--white);
  color: var(--red);
  margin: 0 auto 20px;
  font-size: 22px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}
