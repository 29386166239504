.Profile {
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  margin: 0 auto 30px;
  border-radius: 0 20px 20px 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
.Profile h4 {
  color: var(--red);
  text-align: center;
  margin-bottom: 20px;
}
.Profile label {
  color: var(--red);
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 2px;
}
.Profile .btn-red {
  background-color: var(--red);
  color: var(--white);
}
.Profile .btn-red:hover {
  background-color: var(--gray);
  color: var(--white);
}
.Profile-loading {
  width: 100%;
}
.Profile_subscription-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.Profile_subscription-button {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: var(--red);
  color: var(--white);
  padding: 15px;
  border: 5px solid var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  line-height: 22px;
  transition: 0.2s all;
  position: relative;
}
.Profile_subscription-button:hover {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.85);
  transform: scale(1.1);
}
.Profile_subscription-button span {
  color: var(--handGray);
  font-size: 45px;
  position: absolute;
  top: -20px;
  right: -5px;
}
.Profile_subscription-price {
  font-size: 18px;
  font-weight: bold;
}
