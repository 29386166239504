.Home_container {
  width: 100%;
  min-height: calc(100% - 40px);
  background-color: var(--red);
  padding: 20px 0 30px;
}
.Home_logo {
  width: 90%;
  max-width: 550px;
  position: relative;
  display: block;
  margin: 0 auto 30px;
}
.Home_welcome {
  color: var(--white);
  position: absolute;
  left: -120px;
  top: 48%;
  font-weight: 300;
  font-size: 18px;
}
.Home_logo img {
  width: 100%;
}
.Home_select {
  width: 100%;
  color: var(--white);
  text-align: center;
  display: block;
  margin: 0 auto;
}
.Home_select i {
  font-size: 50px;
  margin-right: 20px;
}
.Home_title-select {
  font-size: 25px;
  font-weight: 300;
}
.Home_line {
  width: 100px;
  height: 3px;
  border-radius: 5px;
  background-color: var(--white);
  display: block;
  margin: 5px auto 30px;
}
@media screen and (max-width: 800px) {
  .Home_welcome {
    position: initial;
    left: initial;
    top: initial;
  }
}
