.Corporative-container {
  padding: 20px 0 50px;
}
.Corporative-title {
  text-align: center;
  color: var(--red);
  margin-bottom: 30px;
}
.Corporative-image {
  width: 100%;
}
.Corporative-content {
  margin-bottom: 70px;
}
.Corporative-content ul {
  padding-left: 17px;
  list-style: none;
}
.Corporative-content ul li::before {
  content: '\2022';
  color: var(--red);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 20px;
}
.Corporative-principal-image img {
  width: 100%;
  max-width: 900px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 15px 15px 0 0;
}
.Corporative-table {
  width: 100%;
  max-width: 700px;
  background-color: var(--red);
  padding: 10px 30px;
  border-radius: 5px 5px 10px 10px;
  color: var(--white);
  display: block;
  margin: 0 auto 30px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}
.Corporative-table table {
  width: 100%;
}
.Corporative-table table td {
  vertical-align: top;
}
.Corporative-box-ul {
  background-color: var(--red);
  padding: 10px 30px;
  border-radius: 5px 5px 10px 10px;
  margin-bottom: 30px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}
.Corporative-box-ul ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.Corporative-box-ul ul li {
  padding: 0 12px;
  color: var(--white);
  text-decoration: underline;
}
.Corporative-box-ul ul li::before {
  content: '';
  width: 0;
  margin-left: 0;
}
.Corporative-section {
  border-bottom: 2px solid var(--black);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.Corporative-section:last-child {
  border-bottom: 0;
}
.Corporative-box-image img {
  width: 85%;
  height: auto;
  display: block;
  margin: 0 auto -30px;
  border-radius: 15px 15px 0 0;
}
.Corporative-table-box-image {
  padding-top: 50px;
}
