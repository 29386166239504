.Content-container {
  padding: 30px 0;
}
.Content-container ul,
.Content-container ol {
  padding-left: 20px;
}
.Content-title,
.Content-subtitle {
  text-align: center;
  color: var(--red);
}
.Content-title {
  font-size: 35px;
}
.Content-subtitle {
  font-size: 30px;
}
.Content-content {
  padding-top: 20px;
}
.Content-content h1 {
  font-size: 30px;
}
.Content-content h2 {
  font-size: 27px;
}
.Content-content h3 {
  font-size: 24px;
}
.Content-content h4 {
  font-size: 20px;
}
.Content-content h5 {
  font-size: 18px;
}
.Content-content h6 {
  font-size: 16px;
}
.Content-content ul,
.Content-content ol {
  padding-left: 20px;
}
.Content-ul-menu ul {
  padding: 0;
  list-style: none;
}
.Content-ul-menu ul a {
  width: 100%;
  display: block;
  padding: 7px 10px 5px;
  border: 1px solid var(--black);
  color: var(--red);
  margin-bottom: 15px;
  font-weight: 600;
  transition: 0.2s all;
}
.Content-ul-menu ul a:hover {
  text-decoration: none;
  background-color: var(--black);
}
