.Imax-video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}
.ImaxHeader-iframe {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}
.ImaxHeader-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Imax-content {
  width: 100%;
  height: auto;
  background-image: url('../../assets/images/imax/background.jpg');
  background-size: cover;
}
.Imax-logos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Imax-imax-logo {
  width: 100%;
  max-width: 420px;
}
.Imax-logo {
  width: 100%;
  max-width: 250px;
}
.Imax-box-title {
  text-align: center;
  padding-top: 20px;
}
.Imax-title-1 {
  color: #fff;
  font-size: 26px;
}
.Imax-title-2 {
  color: #0971ce;
  font-size: 32px;
}
.Imax-circle {
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
  font-weight: bold;
}
.Imax-circle img {
  width: 100%;
  max-width: 150px;
  display: block;
  margin: 0 auto -10px;
}
.Imax-current-movie {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.Imax-current-movie img {
  width: 325px;
  margin-right: 20px;
}
.Imax-movie-trailer {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #000000;
}
.Imax-billboard {
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  max-width: 200px;
  padding: 5px 10px;
  font-weight: bold;
  border: 1px solid #149cde;
  color: #fff;
  background: rgb(4, 52, 96);
  background: radial-gradient(
    circle,
    rgba(4, 52, 96, 1) 0%,
    rgba(20, 156, 222, 0.65) 200%
  );
  border-radius: 10px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .Imax-current-movie {
    flex-direction: column;
  }
  .Imax-current-movie img {
    display: block;
    width: 100%;
    max-width: 250px;
    margin: 0 auto 30px;
  }
}
