.TheaterTitle_content {
  width: 90%;
  text-align: center;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.TheaterTitle_name {
  font-size: 30px;
  background-color: var(--black);
  color: var(--white);
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px 5px 15px 15px;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
  display: inline-block;
  margin-bottom: -20px;
}
.TheaterTitle_address {
  display: inline-block;
  background-color: var(--red);
  color: var(--white);
  padding: 30px 30px 10px;
  font-weight: 600;
  border-radius: 5px 5px 15px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.TheaterTitle_content.small .TheaterTitle_name {
  font-size: 20px;
  border-radius: 5px 5px 10px 10px;
  margin-bottom: -15px;
}
.TheaterTitle_content.small .TheaterTitle_address {
  padding: 25px 20px 10px;
  font-size: 14px;
  border-radius: 5px 5px 10px 10px;
}
