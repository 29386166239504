.Header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--white);
}
.Header.red {
  background-color: var(--red);
}
.Header.black {
  background-color: var(--black);
}
.Header div {
  margin-left: auto;
}
.Header div button {
  background-color: transparent;
  color: var(--white);
  border: 0;
  text-align: right;
  cursor: pointer;
  transition: 0.2s all;
}
.Header div button:focus {
  outline: none;
}
.Header.black div button:hover {
  text-decoration: none;
  color: var(--red);
}
.Header.red div button:hover {
  text-decoration: none;
  color: var(--gray);
}
.Header_content {
  background-color: var(--red);
  position: relative;
  text-align: center;
}
.Header_content .logo {
  width: 100%;
  max-width: 250px;
  margin: 30px 0;
  position: relative;
  z-index: 1;
}
.Header_menu {
  width: 100%;
  height: 45px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: var(--black);
  justify-content: center;
  align-items: center;
  position: relative;
}
.Header_city {
  color: var(--white);
  margin-right: 20px;
  text-align: center;
}
.Header_select {
  width: 100%;
  max-width: 400px;
}
.Header_session-menu {
  display: flex;
  flex-wrap: wrap;
}
.Header_session-menu span {
  margin: 0 5px;
}
.Header a {
  color: var(--white);
  transition: 0.2s all;
}
.Header a:hover {
  color: var(--red);
  text-decoration: none;
}
.Header_username {
  text-transform: capitalize;
}
.Header_menu-container {
  display: flex;
  color: var(--white);
}
.Header_menu-item {
  height: 45px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--white);
  cursor: pointer;
  transition: 0.2s all;
}
.Header_menu-item.active {
  text-decoration: underline;
  text-decoration-color: var(--red);
  text-decoration-thickness: 2px;
}
.Header_menu-item:hover {
  color: var(--red);
  text-decoration: none;
}
.Header_submenu {
  width: 100%;
  min-width: 175px;
  position: absolute;
  top: 45px;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 5;
  background-color: var(--red);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
}
.Header_menu-item:hover > .Header_submenu {
  opacity: 1;
  visibility: visible;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.5);
}
.Header_submenu a {
  padding: 5px 10px;
  color: var(--white);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.2s all;
}
.Header_submenu a:hover {
  color: var(--black);
  text-decoration: none;
}
.Header_button-mobile {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  font-size: 30px;
  background-color: transparent;
  color: var(--white);
  display: none;
}
.Header_button-close {
  display: none;
}
.Header_social {
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 15px;
}
.Header_social a {
  width: 30px;
  height: 30px;
  margin: 2px 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  color: var(--white);
  transition: 0.2s all;
}
.Header_social a:hover {
  background-color: var(--white);
  color: var(--black);
  text-decoration: none;
}
.Header_venom {
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media screen and (max-width: 1300px) {
  .Header_content .logo {
    margin: 10px 0;
  }
}
@media screen and (max-width: 750px) {
  .Header_button-mobile,
  .Header_button-close {
    display: block;
  }
  .Header_button-close {
    font-size: 25px;
    background-color: transparent;
    border: 0;
  }
  .Header_button-close:focus,
  .Header_button-mobile:focus {
    outline: none;
  }
  .Header_menu-container {
    overflow: auto;
    position: fixed;
    width: 250px;
    height: 100%;
    top: 0;
    right: -260px;
    background-color: var(--red);
    flex-direction: column;
    text-align: left;
    z-index: 10;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.75);
    transition: 0.3s all;
  }
  .Header_menu-container.visible {
    right: 0px;
  }
  .Header_menu-item {
    padding: 5px 10px;
    height: auto;
    text-align: left;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .Header_submenu {
    width: 100%;
    position: initial;
    top: initial;
    left: initial;
    visibility: visible;
    opacity: 1;
  }
  .Header_submenu a {
    padding: 5px 15px;
  }
  .Header_submenu a:last-child {
    border-bottom: none;
  }
  .Header_menu-item a {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .Header {
    justify-content: flex-end;
  }
  .Header .date {
    display: none;
  }
  .Header_content {
    text-align: left;
  }
  .Header_content .logo {
    max-width: 225px;
  }
  .Header_venom {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
