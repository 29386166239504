.SliderRecharge-slider {
  width: 100%;
  height: 30px;
  position: relative;
}
.SliderRecharge-slider .SliderRecharge-track {
  top: 10px;
  height: 10px;
}
.SliderRecharge-track.SliderRecharge-track-1 {
  background-color: var(--ligthGray);
}
.SliderRecharge-track.SliderRecharge-track-0,
.SliderRecharge-track.SliderRecharge-track-2 {
  background-color: var(--red);
}
.SliderRecharge-slider .SliderRecharge-thumb {
  top: 0px;
  width: 30px;
  height: 30px;
  line-height: 38px;
  background-color: var(--black);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.SliderRecharge-slider .SliderRecharge-thumb::after {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  background-image: url('../../assets/images/arrow-price.svg');
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px 15px;
  top: 0;
  left: 0;
}
.SliderRecharge-slider .SliderRecharge-thumb:focus {
  outline: none;
}
.SliderRecharge-price {
  color: var(--red);
}
