.CouponDetail_button-conditions {
  width: 200px;
  display: block;
  margin: 0 auto;
  font-size: 12px;
  color: var(--white);
  background-color: transparent;
  border: 0;
  margin-top: 10px;
}
.CouponDetail_button-conditions:hover {
  text-decoration: underline;
}
.CouponDetail_button-conditions:focus {
  outline: none;
}
