.PaymentMethods {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 6;
  visibility: hidden;
  opacity: 0;
  transition: 0.8s all;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.PaymentMethods.visible {
  visibility: visible;
  opacity: 1;
}
.PaymentMethods_container {
  width: 95%;
  max-width: 450px;
  animation: modalOut 0.8s forwards;
}
.PaymentMethods.visible .PaymentMethods_container {
  animation: modalIn 0.8s forwards;
}
.PaymentMethods_content {
  width: 100%;
  border-radius: 5px;
  background-color: var(--white);
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.PaymentMethods_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: var(--white);
  margin-bottom: 30px;
}
.PaymentMethods_footer i {
  margin-right: 5px;
  color: #2ecc71;
}
.PaymentMethods_footer img {
  width: 55px;
  margin-left: 5px;
}
.PaymentMethods_form {
  width: 100%;
  background-color: #f3f3f3;
  padding: 10px;
}
.PaymentMethods_form h5 {
  margin-bottom: 20px;
}
.PaymentMethods_continue {
  width: 100%;
  height: 50px;
  background-color: #3582b7;
  border-radius: 0;
  border: 0;
  color: var(--white);
  transition: 0.2s all;
}
.PaymentMethods_continue:hover {
  background-color: #236591;
}
.PaymentMethods_continue:focus {
  outline: none;
}
.PaymentMethods_select {
  width: 100%;
  height: 45px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--white);
  padding: 0 10px;
}
.PaymentMethods_select i {
  font-size: 20px;
  width: 30px;
  margin-right: 10px;
}
.PaymentMethods_select img {
  width: 30px;
  margin-right: 10px;
}
.PaymentMethods_step {
  font-size: 12px;
  color: #818181;
}
.PaymentMethods_box-input {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 40px;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  background-color: var(--white);
  margin-bottom: 10px;
}
.PaymentMethods_box-input i {
  margin-right: 10px;
  color: #236591;
}
.PaymentMethods_box-input input,
.PaymentMethods_box-input select {
  width: 100%;
  height: 38px;
  border: 0;
}
.PaymentMethods_box-input input:focus,
.PaymentMethods_box-input select:focus {
  outline: none;
}
.PaymentMethods_row-input {
  display: flex;
}
.PaymentMethods_row-input .PaymentMethods_box-input:nth-child(1) {
  width: 40%;
}
.PaymentMethods_row-input .PaymentMethods_box-input:nth-child(2) {
  margin: 0 10px;
  width: 30%;
}
.PaymentMethods_month {
  width: 45px !important;
}
.PaymentMethods_row-input .PaymentMethods_box-input:nth-child(3) {
  width: 30%;
}
.PaymentMethods_back {
  text-align: left;
  width: 100%;
  border: 0;
  background-color: var(--white);
  height: 30px;
  padding: 0 20px;
  font-size: 14px;
  color: #236591;
}
.PaymentMethods_back i {
  margin-right: 10px;
}
.PaymentMethods_doc-number {
  width: calc(60% - 10px) !important;
  margin: 0 0 0 10px !important;
}
.PaymentMethods_radio label:nth-child(2) {
  margin-left: 20px;
}
.PaymentMethods_radio label input {
  margin-right: 5px;
}
.PaymentMethods_resume {
  width: 100%;
  padding: 10px 12px;
  border-bottom: 1px solid #9b9b9b;
  position: relative;
}
.PaymentMethods_title {
  font-size: 18px;
}
.PaymentMethods_description {
  font-size: 14px;
  color: #9b9b9b;
}
.PaymentMethods_price {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PaymentMethods_description-confectionery h2 {
  font-size: 17px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: var(--black);
}
.PaymentMethods_price-total {
  border-top: 1px solid var(--gray);
  padding: 10px 0;
}
.PaymentMethods_price strong {
  font-size: 18px;
}
.PaymentMethods_close {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: #236591;
  background-color: transparent;
  border: 0;
  font-size: 30px;
  line-height: 0;
}
.PaymentMethods_description-item span,
.PaymentMethods_description-item a {
  color: var(--red);
  font-weight: bold;
}
.PaymentMethods_description-item a:hover {
  color: var(--red);
}
.PaymentMethods_description .form-group {
  margin-bottom: 10px;
}
.PaymentMethods_description .form-group input[type='checkbox'] {
  margin-right: 5px;
}
.Payment_subscription-tc {
  margin-top: 10px;
}
.Payment_subscription-tc a {
  color: var(--red);
}
.Payment_subscription-tc a:hover {
  color: var(--redHover);
}
.Payment_subscription-tc input {
  margin-right: 5px;
}
.PaymentMethods_subscription {
  width: 300px;
  padding: 15px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: -310px;
  top: 0;
  background-color: var(--white);
  border-radius: 5px;
}
.PaymentMethods_subscription ul {
  margin-left: 15px;
  margin-bottom: 0;
  margin-top: 10px;
}

@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}
@media screen and (max-width: 1100px) {
  .PaymentMethods_subscription {
    width: 200px;
    left: -210px;
  }
}
@media screen and (max-width: 1000px) {
  .PaymentMethods_subscription {
    width: 100%;
    position: initial;
    top: initial;
    left: initial;
    margin: 10px 0;
    font-size: 14px;
  }
}
