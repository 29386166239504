.site-container {
  padding: 20px 0 40px;
}

.MovieDetails {
  width: 100%;
  padding: 25px 25px;
  border-radius: 20px;
  background: var(--red);
  position: relative;
  color: var(--white);
  font-size: 16px;
}

.MovieDetails div:first-child img {
  width: 100%;
  margin-bottom: 10px;
}

.MovieDetails-caja-titulo {
  display: flex;
  flex-direction: column;
}

.MovieDetails-caja-titulo {
  font-size: 2rem;
  text-align: center;
}

.MovieDetails-caja-titulo p {
  margin-bottom: 10px;
}

.MovieDetails-caja-titulo p:nth-child(2) {
  font-size: 1rem;
}

.MovieDetails-caja-titulo p:nth-child(3) {
  font-size: 1.25rem;
}

.MovieDetails div div:nth-child(2) div:nth-child(2) iframe {
  width: 100%;
}

.MovieDetails-sinopsis {
  margin-top: 15px;
  text-align: center;
}

.MovieDetails div div:nth-child(2) div:nth-child(4) div {
  margin-top: 5px;
  text-align: start;
}

.MovieDetails_video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 15px;
}

.MovieDetails_video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 470px) {
  .MovieDetails div div:nth-child(2) div:nth-child(4) div:nth-child(5) {
    display: inline-table;
  }
}

@media (max-width: 370px) {
  .MovieDetails div div:nth-child(2) div:nth-child(4) div {
    display: inline-table;
  }
  .MovieDetails div div:nth-child(2) div:nth-child(4) div:nth-child(2) p {
    max-width: 100% !important;
  }
}
