.MovieList_container .col-sm-6.col-md-3 {
  margin-bottom: 30px;
}
.MovieList_content {
  padding: 30px 20px 30px;
}
.MovieList a {
  color: #000000;
}
.MovieList a:hover {
  text-decoration: none;
  color: #000000;
}
.MovieList_item {
  margin: 10px 0;
  transition: 0.2s all;
}
.MovieList a:hover .MovieList_item {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
.MovieList img {
  width: 100%;
}
.MovieList_description {
  padding: 10px 15px;
  background-color: var(--white);
  margin-bottom: 10px;
  text-align: center;
}
.MovieList_original-title {
  background-color: var(--gray);
  color: var(--white);
  text-align: center;
  padding: 5px 15px;
  font-size: 12px;
}
.MovieList_type {
  background-color: var(--red);
  color: var(--white);
  text-align: center;
  padding: 5px 15px;
  font-size: 12px;
  text-transform: uppercase;
}
.MovieList_detail {
  font-weight: 700;
  color: #e50019;
  font-size: 18px;
}
