.Footer {
  background: #e50019;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 8px solid #000000;
}
.Footer-container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}
.Footer-row {
  padding: 10px 15px;
  width: 20%;
}
.Footer-row:first-child {
  width: 25%;
}
.Footer-row:last-child {
  width: 15%;
}
.Footer img {
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 0 auto 20px;
}
.Footer-menu {
  display: flex;
  flex-direction: column;
}
.Footer-menu h5 {
  width: 100%;
  font-weight: 500;
  border-bottom: 4px solid var(--black);
  padding-bottom: 8px;
  margin-bottom: 8px;
  font-size: 18px;
  color: var(--white);
}
.Footer-menu a,
.Footer-menu div {
  color: var(--white);
  font-weight: 300;
  margin-bottom: 5px;
  transition: 0.2s all;
  display: block;
  cursor: pointer;
}
.Footer-social-title {
  width: 80px;
  margin: 0 auto;
  border-bottom: 2px solid var(--white);
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  color: var(--white);
}
.Footer-social {
  width: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.Footer-social a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--white);
  transition: 0.2s all;
}
.Footer-social a:hover {
  color: var(--white);
  text-decoration: none;
  background: rgba(255, 255, 255, 0.2);
}
.Footer-menu a:hover,
.Footer-menu div:hover {
  text-decoration: none;
  color: var(--gray);
}
.Footer-menu img {
  width: 80%;
}
.Footer-menu h6 {
  text-align: center;
}
.Footer-bepass {
  max-width: 200px;
}
.Footer_apps {
  text-align: center;
  color: #ffffff;
  padding: 20px;
  border-top: 8px solid #000000;
}
.Footer_apps h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
.Footer_apps-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Footer_apps-menu a {
  display: block;
  padding: 10px 20px;
}
.Footer_apps-menu img {
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  height: auto;
}
@media screen and (max-width: 769px) {
  .Footer-row {
    width: 100% !important;
  }
}
