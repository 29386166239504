.ImaxNextRelease-container {
  width: 100%;
  padding: 30px 0;
  background-image: url('../../assets/images/imax/background.jpg');
  background-size: cover;
  background-position: center bottom;
}
.ImaxNextRelease-container .Imax-billboard {
  max-width: 250px;
  margin-bottom: 30px;
}
