.TheaterServices_content {
  width: 90%;
  max-width: 1000px;
  background-color: var(--white);
  margin: -40px auto 30px;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32);
  padding-top: 30px;
  padding-bottom: 10px;
}
.TheaterServices_content h1 {
  color: var(--red);
  text-align: center;
  font-size: 22px;
}
.TheaterServices_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.TheaterServices_item {
  width: 250px;
  text-align: center;
  padding: 0 20px;
}
.TheaterServices_item span {
  font-size: 80px;
}
.TheaterServices_item h3 {
  width: 130px;
  color: var(--red);
  font-size: 20px;
  margin: 0 auto;
}
.TheaterServices_item p {
  font-size: 14px;
  color: var(--grayText);
}
